import bike from '../../common/assets/icons/icon_bike.svg';
import bus from '../../common/assets/icons/icon_bus.svg';
import freeway from '../../common/assets/icons/icon_freeway.svg';
import metro from '../../common/assets/icons/icon_metro.svg';
import parking from '../../common/assets/icons/icon_parking.svg';

export const Icons = {
    bike,
    bus,
    freeway,
    metro,
    parking
};
