import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

export const Welcome: React.FC = () => {
    const [screen, setScreen] = useState<number>(0);
    const [effect, setEffect] = useState<string>('fade-in');

    const slides = [
        <div className={effect}>
            <h3 className='font-weight-bold'>Bienvenido</h3>
            <h3 className='font-weight-bold'>Welcome</h3>
            <h3 className='font-weight-bold'>Bienvenue</h3>
        </div>,
        <div className={effect}>
            <h3 className='font-weight-bold'>¡Tu eres parte del NAR!</h3>
            <h3 className='font-weight-bold'>You are part of the NAR!</h3>
            <h3 className='font-weight-bold'>Vous faites partie de la NAR!</h3>
        </div>,
        <>
            <Redirect to='/virtual-tour' />
        </>,
    ];

    useEffect(() => {
        setEffect('fade-in');

        setTimeout(() => {
            setEffect('fade-out');

            setTimeout(() => {
                screen !== 2 && setScreen(screen + 1);
            }, 2000);
        }, 3000);
    }, [screen]);

    return slides[screen];
};
