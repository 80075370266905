import React, { useState } from 'react';
import { Images } from './images';

export const ImageGallery: React.FC = () => {
    const [image, setImage] = useState<number>(0);

    return (
        <div className='row'>
            <div className='col-md-8 mb-4'>
                <div className='spinner spinner-border text-light' role='status'>
                    <span className='sr-only'>Loading...</span>
                </div>
                <img className='viewer d-block' src={Images[image]} alt='' />
            </div>
            <div className='col-md-4 gallery-preview'>
                <div className='row px-2'>
                    {Images.map((img, index) => (
                        <div className='col-4 mb-2 px-1' onClick={() => setImage(index)}>
                            <img
                                className={`preview ${
                                    image === index ? 'img-picked' : ''
                                }`}
                                src={img}
                                alt=''
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
