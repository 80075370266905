import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { Gallery } from 'views/Gallery';
import { Home } from 'views/Home';

/**
 * Method responsible for implementing the security mechanism and view routing
 */
const App: React.FC = () => {
    return (
        <HashRouter>
            <Switch>
                <Route path='/virtual-tour' component={Gallery} />
                <Route component={Home} />
            </Switch>
        </HashRouter>
    );
};

export default App;
