import opening0 from '../../common/assets/images/opening/0.jpg';
import opening1 from '../../common/assets/images/opening/1.jpg';
import opening2 from '../../common/assets/images/opening/2.jpg';
import opening3 from '../../common/assets/images/opening/3.jpg';
import opening4 from '../../common/assets/images/opening/4.jpg';
import opening5 from '../../common/assets/images/opening/5.jpg';
import opening6 from '../../common/assets/images/opening/6.jpg';
import opening7 from '../../common/assets/images/opening/7.jpg';
import opening9 from '../../common/assets/images/opening/9.jpg';
import opening10 from '../../common/assets/images/opening/10.jpg';
import opening11 from '../../common/assets/images/opening/11.jpg';
import opening12 from '../../common/assets/images/opening/12.jpg';
import opening13 from '../../common/assets/images/opening/13.jpg';
import opening14 from '../../common/assets/images/opening/14.jpg';
import img0 from '../../common/assets/images/gallery/0.jpg';
import img1 from '../../common/assets/images/gallery/1.jpg';
import img2 from '../../common/assets/images/gallery/2.jpg';
import img3 from '../../common/assets/images/gallery/3.jpg';
import img4 from '../../common/assets/images/gallery/4.jpg';
import img5 from '../../common/assets/images/gallery/5.jpg';
import img6 from '../../common/assets/images/gallery/6.jpg';
import img7 from '../../common/assets/images/gallery/7.jpg';
import img8 from '../../common/assets/images/gallery/8.jpg';

export const Images = [
    opening0,
    opening1,
    opening2,
    opening3,
    opening4,
    opening5,
    opening6,
    opening7,
    opening9,
    opening10,
    opening11,
    opening12,
    opening13,
    opening14,
    img0,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
];
