import React, { useState } from 'react';
import { RibbonProps } from './interfaces/ribbon.interface';

export const Ribbon: React.FC<RibbonProps> = (props) => {
    const [cut, setCut] = useState<boolean>(false);

    const cutRibbon = () => {
        setCut(true);
        props.onCut();
    };

    return (
        <div className='ribbon-wrapper' onTouchEnd={() => cutRibbon()}>
            <div
                className={`ribbon ribbon-left ${cut && 'ribbon-left-cut'}`}
                onClick={() => cutRibbon()}
                onMouseLeave={(event) => {
                    event.buttons && cutRibbon();
                }}
            ></div>
            <div
                className={`ribbon ribbon-right ${cut && 'ribbon-right-cut'}`}
                onClick={() => cutRibbon()}
                onMouseLeave={(event) => {
                    event.buttons && cutRibbon();
                }}
            ></div>
        </div>
    );
};
