import React, { useState } from 'react';
import './home.css';
import blueLabel from '../../common/assets/images/blue-label.png';
import narLogo from '../../common/assets/images/nar-logo.png';
import { Ribbon } from './ribbon';
import { Welcome } from './welcome';

/**
 * View responsible for providing an interface for the welcome screen
 */
export const Home: React.FC = () => {
    const [isCut, setIsCut] = useState<boolean>(false);

    return (
        <div className='home-hero'>
            <img className='home-logo header-logo ' src={blueLabel} alt='' />
            <div className='ribbon-and-text-wrapper'>
                <Ribbon onCut={() => setIsCut(true)} />

                <div className={`home-hero-content ${isCut && 'ribbon-label-cut'}`}>
                    {!isCut && <p className='ribbon-label'>Toca | Click | Presse</p>}
                    {isCut && <Welcome />}
                </div>
            </div>
            <img className='home-footer' src={narLogo} alt='' />
        </div>
    );
};
