import React from 'react';
import narLogo from '../../common/assets/images/nar-logo.png';
import blueLabel from '../../common/assets/images/blue-label.png';
import './gallery.css';
import { Icons } from './icons';
import { ImageGallery } from './image-gallery';

export const Gallery: React.FC = () => {
    return (
        <>
            <header className='container gallery-header mb-5'>
                <img className='header-logo' src={blueLabel} alt='' />
            </header>

            <section className='container text-white mb-5'>
                <h4 className='font-weight-bold mb-4'>
                    Take a look at our new offices in Mexico City
                </h4>
                <ImageGallery />
            </section>

            <footer className='container text-white'>
                <div className='row'>
                    <div className='col-sm mb-5'>
                        <h4 className='font-weight-bold mb-4'>WeWork Reforma Latino</h4>
                        <h5>
                            Paseo de la Reforma 296 <br />
                            Colonia Juárez <br />
                            México City 06600
                        </h5>
                    </div>

                    <div className='col-sm mb-5'>
                        <h4 className='font-weight-bold mb-4'>Nearby transport</h4>
                        <ul className='list-unstyled'>
                            <li className='media'>
                                <img src={Icons.metro} className='mr-3 icon' alt='...' />
                                <div className='media-body'>
                                    <h5 className='mt-0 mb-1'>
                                        Insurgentes and Reforma metrobus
                                    </h5>
                                </div>
                            </li>

                            <li className='media'>
                                <img
                                    src={Icons.parking}
                                    className='mr-3 icon'
                                    alt='...'
                                />
                                <div className='media-body'>
                                    <h5 className='mt-0 mb-1'>
                                        Parking slots inside the building
                                    </h5>
                                </div>
                            </li>

                            <li className='media'>
                                <img
                                    src={Icons.freeway}
                                    className='mr-3 icon'
                                    alt='...'
                                />
                                <div className='media-body'>
                                    <h5 className='mt-0 mb-1'>Reforma</h5>
                                </div>
                            </li>

                            <li className='media'>
                                <img src={Icons.bus} className='mr-3 icon' alt='...' />
                                <div className='media-body'>
                                    <h5 className='mt-0 mb-1'>Reforma metrobus</h5>
                                </div>
                            </li>

                            <li className='media'>
                                <img src={Icons.bike} className='mr-3 icon' alt='...' />
                                <div className='media-body'>
                                    <h5 className='mt-0 mb-1'>
                                        Bicycle parking area inside the building
                                    </h5>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className='col-sm mb-5'>
                        <img className='footer-logo' src={narLogo} alt='' />
                    </div>
                </div>
            </footer>
        </>
    );
};
